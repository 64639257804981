import {Tabs, Tab, Container} from 'react-bootstrap';
import './style.scss';

function Codeeditor(){
    return(

        <div className='codeeditior_main'>
        <div className='codeeditior_main_body'>
            <div className='neviagationDots'>
                <span className='redDot'></span>
                <span  className='OrangeDot'></span>
                <span className='GreenDot'></span>
            </div>
            <Tabs defaultActiveKey="CodeSnippets" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="CodeSnippets" title="Code Snippets">
                    <div className='precode_main'>
                        <pre className="code">

                        <div className="css-typing">
                            <p> <span className='lightblue'>import</span> <span className='darkblue'> requests </span></p>
                            <p> &nbsp; </p>
                            <p> <span className='darkblue'> url</span> = <span className='lightgreen'>"https://aerodatabox.p.rapidapi.com/flights/%7BsearchBy%7D/KL1395/2020-06-10"</span></p>
                            <p> &nbsp; </p>
                            <p> <span className='darkblue'>headers </span> =  </p>
                            <p> <span className='lightgreen'> 'x-rapidapi-host': "aerodatabox.p.rapidapi.com",</span> </p>
                            <p> <span className='lightgreen'> 'x-rapidapi-key': "SIGN-UP-FOR-KEY"</span> </p>
                            <p> &nbsp; </p>
                            <p> &nbsp; </p>
                            <p> <span className='darkblue'> response = requests.request("GET", url, headers=headers) </span> </p>
                            <p> &nbsp; </p>
                            <p> <span className='lightblue'> print</span>(<span className='darkblue'>response.text</span>)  </p>
                        </div>
                            {/* 
                            <code className='codeanimation'><span className='lightblue'>import</span> <span className='darkblue'> requests </span></code>
                            <code> </code>
                            <code className='codeanimation'> <span className='darkblue'> url</span> = <span className='lightgreen'>"https://aerodatabox.p.rapidapi.com/flights/%7BsearchBy%7D/KL1395/2020-06-10"</span> </code>
                            <code> </code>
                            <code><span className='darkblue'>headers </span> =  </code>
                            <code className='codeanimation'>   <span className='lightgreen'> 'x-rapidapi-host': "aerodatabox.p.rapidapi.com",</span> </code>
                            <code className='codeanimation'>   <span className='lightgreen'> 'x-rapidapi-key': "SIGN-UP-FOR-KEY" </span></code>
                            <code> </code>
                            <code> </code>
                            <code className='codeanimation'> <span className='darkblue'> response = requests.request("GET", url, headers=headers) </span> </code>
                            <code> </code> 
                            <code className='codeanimation'> <span className='lightblue'> print</span>(<span className='darkblue'>response.text</span>) </code>
                            <code> </code>
                            <code> </code>
                            <code> </code>
                            <code> </code> */}
                        </pre>
                    </div>
                    <div className='ex_showprecode'>
                        <div className='ex_showprecode_body'>
                            <pre className="code">
                            <code> <span className='darkblue'>url</span> = <span className='lightgreen'>"https://aerodatabox.p.rapidapi.com/flights/%7BsearchBy%7D/KL1395/2020-06-10"</span> </code>
                            
                            </pre> 
                        </div>
                        <div className='ex_showprecode_body'>
                            <pre className="code">
                                <code> <span className='darkblue'> headers </span> =  </code>
                                <code>         <span className='lightgreen'> 'x-rapidapi-host': "aerodatabox.p.rapidapi.com",</span> </code>
                                <code>         <span className='lightgreen'> 'x-rapidapi-key': "SIGN-UP-FOR-KEY" </span></code>
                               
                            </pre> 
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="ExampleResponses" title="Example Responses">
                    <div className='precode_main'>
                        <pre className="code">
                            
                        <div className="css-typing">
                            <p> <span className='lightblue'>import</span> <span className='darkblue'> requests </span></p>
                            <p> &nbsp; </p>
                            <p> <span className='darkblue'> url</span> = <span className='lightgreen'>"https://aerodatabox.p.rapidapi.com/flights/%7BsearchBy%7D/KL1395/2020-06-10"</span></p>
                            
                           
                        </div>
                            
                        </pre>
                    </div>
                </Tab>
                <Tab eventKey="Results" title="Results" >
                <div className='precode_main'>
                        <pre className="code">
                        <div className="css-typing">
                            <p>  <span className='lightblue'>import</span> <span className='darkblue'> requests </span></p>
                            <p> &nbsp; </p>
                            <p> <span className='darkblue'> url</span> = <span className='lightgreen'>"https://aerodatabox.p.rapidapi.com/flights/%7BsearchBy%7D/KL1395/2020-06-10"</span></p>
                            <p> &nbsp; </p>
                            <p> <span className='darkblue'>headers </span> =  </p>
                            <p> <span className='lightgreen'> 'x-rapidapi-host': "aerodatabox.p.rapidapi.com",</span> </p>
                            <p> <span className='lightgreen'> 'x-rapidapi-key': "SIGN-UP-FOR-KEY"</span> </p>
                           
                        </div>
                        </pre>
                    </div>
                </Tab>
            </Tabs>
        </div>
        </div>

        
      
    );
}
export default Codeeditor;