import {Navbar, Container, Nav, NavDropdown, Image} from 'react-bootstrap';
import { Link } from "react-router-dom";
import './style.scss';

function Centerheading ({Heading, HeadingColor, Description="", headingclass}){  
	return (
      <>
       
            <div className='centerheading_main text-center wow fadeInUp' data-wow-delay="0.3s">
                <h2 className={headingclass}>{Heading} <span>{HeadingColor}</span> </h2>
                <p>{Description}</p>
            </div>
            
        
	  </>
	 )
}
export default Centerheading;