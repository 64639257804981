
import Centerheading from '../../Components/Centerheading';
import {Container, Image, Row, Col} from 'react-bootstrap';
import './style.scss';
import Buttontext from '../../Components/Button';

function Getstartbar (){  
	return (
      <>
	  <div className='Getstartbar_main'>
		  <Container>
			<Row className='align-item-center'>
                <Col md={9}>
                    <h3 className='wow fadeInLeft' data-wow-delay="0.3s">TokenStream - the <b>most customizable API platform</b> for all Crypto <b>News</b>.</h3>
                </Col>
                <Col md={3}>
                    <div className='wow fadeInRight' data-wow-delay="0.3s">
                        <Buttontext Btntext="GET STARTED" btnclass="btn-orange" />
                    </div>
                </Col>
            </Row>
         </Container>
      </div>
	  </>
	 ) 
}
export default Getstartbar;