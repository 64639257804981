
import {Col, Container, Image, Row, Button} from 'react-bootstrap';
import './style.scss';
import SubheadingPoint from './SubheadingPoint';
import {WOW} from 'wowjs';
import {useEffect, useState} from 'react';

function LeftRight ({rightsideimage, type=1}){ 


  const[topPosition,setTopPosition]=useState(0);
  useEffect(() => {
    
    if(topPosition === 10){
      const wow = new WOW({
        live: false
      }).init();
    }
    
  }, [topPosition])
  useEffect(() => {
   
   let interval =  setInterval(function(){
      var top  = window.pageYOffset || document.documentElement.scrollTop;
      if(top <= 0){ setTopPosition(10); clearInterval(interval);}
      
    },100);
     //setTopPosition(top);
  },[]);

	return (
      <>
	  <Container className='main_leftright_space'>
          <Row className='align-item-center'>
              <Col lg={5}>
                  <div className='leftside_contant'>
                  {type===1 && <>
                    <div className='leftsidecontant_mainbutton'>
                      <h2 className='wow fadeInUp'  data-wow-delay="0.3s">Analytic Dashboard powered by <span>AI</span> and <span>ML</span></h2>
                      <SubheadingPoint subheading="Heading Text" 
                      subdescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry." 
                      />

                      <SubheadingPoint subheading="Heading Text 2" 
                      subdescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry." 
                      />
                      <a href="https://quizzical-agnesi-983dbf.netlify.app/#/dashboard" className='btn btn-orange btn-havealook wow fadeInUp' data-wow-delay="0.7s" > Have a Look </a>
                      {/* <Button onClick={} className="btn-orange wow fadeInUp" data-wow-delay="0.8s" >Have a Look</Button> */}
                  </div></>}

                  {type===2 && <>
                    <div>
                      <h2 className='wow fadeInUp'  data-wow-delay="0.3s">The  <span>News API </span> get all  <span>Crypto News </span> in one place</h2>
                      <p className='wow fadeInUp' data-wow-delay="0.5s">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      <p className='wow fadeInUp' data-wow-delay="0.7s">Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  </div>
                  </>}
                </div>
              </Col>
              <Col lg={7}>
                  <div className='img_responsive Imagebg_Shadow wow fadeInUp' data-wow-delay="0.3s">
                    <Image src={rightsideimage} />
                  </div>
              </Col>
          </Row>
      </Container>
	  </>
	 ) 
}
export default LeftRight;