
import {Col, Container, Image, Row} from 'react-bootstrap';
import './style.scss';
import Codeeditor from './Codeeditor';

function DiscoverAPI ({heading, headingcolor, imageDiscoversrc}){  
	return (
      <>
      <Container>
          <Row>
            <div className='DiscoverAPI_main text-center'>
                <h4 className='wow fadeInUp'  data-wow-delay="0.3s">{heading} <span>{headingcolor}</span></h4>
            </div>
            </Row>
            <Row>
                <Codeeditor />
            </Row>
      </Container>
	  </>
	 ) 
}
export default DiscoverAPI;