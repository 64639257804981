import {Col, Container, Row, Image} from 'react-bootstrap';
import './style.scss';
import getaccessImage from '../../Static/getaccess_image.png';
import { FaCheck } from 'react-icons/fa';

function Getaccess (){  
	return (
      <>
	  	<Container fluid className='get_access_main'>
          <Row>
                <Col md={6}>
                    <div className='leftside_getaccess'>
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">
                        Tokenstream get access to early project illustration of tokenstream UI
                        </h2>
                        <p className='wow fadeInUp' data-wow-delay="0.5s">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        </p>
                        <h4 className='wow fadeInUp' data-wow-delay="0.7s"><FaCheck/> Heading Text</h4>
                        <p className='wow fadeInUp' data-wow-delay="0.7s">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </Col>
                <Col md={6} className='paddingRightnone wow fadeInRight' data-wow-delay="0.3s">
                   <div className="img_responsive Imagebg_Shadow"> <Image src={getaccessImage} /> </div> 
                </Col>
          </Row>
        </Container>
	  </>
	 ) 
}
export default Getaccess;