import {Tabs, Container, Tab, Image} from 'react-bootstrap';
import './style.scss';
import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';


function Indicestabs (){  

	return (
      <>
	    <div className='Indicesdetails_tabs'>
			<Tabs defaultActiveKey="Summary" id="uncontrolled-tab-example" className="mb-3">
				<Tab eventKey="Summary" title="Summary">
					<div>
						<p>
							PLAY provides exposure to the most in demand sectors defining the Metaverse. It seeks to track an index of blockchain gaming, infrastructure and entertainment projects. Web3 backers looking to gain exposure to high liquidity Metaverse assets should consider this token.
						</p>
						<ul>
							<h3>PLAY allows investors to:</h3>
							<li><p><FaRegCheckCircle/> Invest in the paradigm-shifting Metaverse ecosystem powered by blockchain</p></li>
							<li><p><FaRegCheckCircle/> Diversify their portfolios while delegating research and due diligence to the PieDAO community</p></li>
							<li><p><FaRegCheckCircle/> Mitigate gas costs from managing their positions</p></li>
						</ul>
					</div>
				</Tab>
				<Tab eventKey="Objective" title="Objective">
				<div>
						<p>
							PLAY provides exposure to the most in demand sectors defining the Metaverse. It seeks to track an index of blockchain gaming, infrastructure and entertainment projects. Web3 backers looking to gain exposure to high liquidity Metaverse assets should consider this token.
						</p>
					</div>
				</Tab>
				<Tab eventKey="Methodology" title="Methodology">
					<div>
						<p>
							PLAY provides exposure to the most in demand sectors defining the Metaverse. It seeks to track an index of blockchain gaming, infrastructure and entertainment projects. Web3 backers looking to gain exposure to high liquidity Metaverse assets should consider this token.
						</p>
						<ul>
							<h3>PLAY allows investors to:</h3>
							<li><p><FaRegCheckCircle/> Invest in the paradigm-shifting Metaverse ecosystem powered by blockchain</p></li>
							<li><p><FaRegCheckCircle/> Diversify their portfolios while delegating research and due diligence to the PieDAO community</p></li>
							<li><p><FaRegCheckCircle/> Mitigate gas costs from managing their positions</p></li>
						</ul>
					</div>
				</Tab>
				<Tab eventKey="Operation" title="Operation">
				<div>
						<p>
							PLAY provides exposure to the most in demand sectors defining the Metaverse. It seeks to track an index of blockchain gaming, infrastructure and entertainment projects. Web3 backers looking to gain exposure to high liquidity Metaverse assets should consider this token.
						</p>
					</div>
				</Tab>
			</Tabs>
		</div>
	  </>
	 )
}
export default Indicestabs;