import React from "react";
//import "./styles.css";
import Header from './Header';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import IconAllocatied from '../Static/icon_Allocation.svg'

import BootstrapTable from "react-bootstrap-table-next";
import Progressbar from '../Components/Progressbar';

const itemFormatter = (cell, row) =><div className="assetname_item_img"> <img src={IconAllocatied}></img><p>{row.assetname}</p> </div>;
const allocationFormatter = (cell, row) => <div className="circel_progressbar"> <Progressbar percentage={row.allocation} />   </div>;
const strategyFormatter = (cell, row) => <div className="strategy_none">{row.strategy}</div>;
const products = [
  { assetname: "MANA", allocation:75, price: '$ 2.47', apy: "None", strategy: "None"  },
  { assetname: "SAND", allocation:65, price: '$ 3.17', apy: "None", strategy: "None" },
  { assetname: "AXS", allocation:15, price: '$ 2.47', apy: "None", strategy: "None" },
  { assetname: "MANA", allocation:15, price: '$ 3.17', apy: "None", strategy: "None" },
  { assetname: "MANA", allocation:75, price: '$ 2.47', apy: "None", strategy: "None"  },
  { assetname: "SAND", allocation:65, price: '$ 3.17', apy: "None", strategy: "None" },
  { assetname: "AXS", allocation:15, price: '$ 2.47', apy: "None", strategy: "None" },
  { assetname: "MANA", allocation:15, price: '$ 3.17', apy: "None", strategy: "None" }
];

const columns = [
  {
    dataField: "assetname",
    text: "Asset Name",
    sort: false, 
    formatter: itemFormatter
  },
  {
    dataField: "allocation",
    text: "Allocation",
    sort: false,
    formatter: allocationFormatter
  },
  {
    dataField: "price",
    text: "Product Price", 
    sort: false
  },
  {
    dataField: "apy",
    text: "APY",
    sort: false
  },
  {
    dataField: "strategy",
    text: "Strategy",
    sort: false,
    formatter: strategyFormatter
  }
  
];

export default function TreeTable() {
  return (
    <div className="App">
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={products}
        columns={columns}
        bordered={ false }
      />
    </div>
  );
}
