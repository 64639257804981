import {Container, Row, Col,  Image} from 'react-bootstrap';
import { FaPaperPlane, FaTwitter, FaGithubAlt } from 'react-icons/fa';
import logo from '../Static/Logo.svg';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {useEffect} from 'react';


function Footer (){  

    const { pathname } = useLocation();
    useEffect(() => {
    window.scrollTo(0, 0);
    }, [pathname]);

	return (
      <>
        <div className='Footer_main'>
        <div className='footer_logo_main'>
            <Container>
                <Row>
                    <Col>
                        <div className='text-center footer_logo'>
                            <Image src={logo} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Container>
            <Row>
                <Col md="3">
                    <ul>
                        <h3>Who we are</h3>
                        <li><Link to="/about" > About </Link></li>
                        <li><Link to="/about" > Team </Link></li>
                        <li><Link to="/" > Work With Us </Link></li>
                    </ul>
                </Col>
                <Col md="3">
                    <ul>
                        <h3>Resources</h3>
                        <li><Link to="/indices" > Indice </Link></li>
                        <li><Link to="/" > API Docs </Link></li>
                        <li><Link to="/pricing" > Pricing </Link></li>
                        <li><Link to="/" > List Your Project </Link></li>
                    </ul>
                </Col>
                <Col md="3">
                    <ul>
                        <h3>Other LInks</h3>
                        <li><Link to="/" > Terms of Use   </Link></li>
                        <li><Link to="/" > Privacy Releases </Link></li>
                        <li><Link to="/" > FAQ </Link></li>
                        <li><Link to="/contact" > Contact </Link></li>
                    </ul>
                </Col>
                <Col md="3" className='socialFooterlinks'>
                    <ul>
                        <h3>Social</h3>
                        <li><Link to="/" >  <FaTwitter/> Twitter </Link></li>
                        <li><Link to="/" >  <FaGithubAlt/> Discord </Link></li>
                        <li><Link to="/" >  <FaPaperPlane/>Telegram </Link></li>
                    </ul>
                </Col>
            </Row>
        </Container>
        
        <div className='Footercopyright'>
            <Container>
                <Row>
                    <Col>
                        <div className='text-center'>
                            <p>© 2022 TokenStream. All Rights Reserved.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </div>
	  </>

	 ) 
}
export default Footer;