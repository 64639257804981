import "./style.scss";
import { Container, Row, Col, Image, Carousel} from 'react-bootstrap';
import {useState} from 'react';
import sliderimage1 from '../../Static/slider-image1.png';
import sliderimage2 from '../../Static/slider-image2.png';
import sliderimage3 from '../../Static/slider-image3.png';
import sliderimage4 from '../../Static/slider-image4.png';
import bgImageslider from '../../Static/bg_imageslider.png';

function AboutSlider (){  
    const [sliderkey, setSliderkey] = useState("0");
    const sliderkeyHandle = () =>{
        setSliderkey("0")
        //console.log("!11")
    }

	const collections = {
		0:{
			name : "Sarah Leitch",
			desc : "Sarah Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},
		1:{
			name : "Nick John",
			desc : "Nick Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},
		2:{
			name : "William",
			desc : "William Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},
		3:{
			name : "Marry Leitch",
			desc : "Marry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},
		4:{
			name : "John Doe",
			desc : "Nick Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},
		5:{
			name : "Mark David",
			desc : "William Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},
		6:{
			name : "Marry Doe",
			desc : "Marry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},
		7:{
			name : "Harry",
			desc : "Marry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},
		8:{
			name : "William Doe",
			desc : "Marry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
		},

	}

	const [index, setIndex] = useState(0);
	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	return (
      <>
	  	<Row className='align-item-center'>
			<Col md={6} className="leftside_contant_about">
				<div className='Abouttopcontant'>
					<h2  className='wow fadeInUp' data-wow-delay="0.3s"> {collections[sliderkey].name} </h2>
					<p className='wow fadeInUp' data-wow-delay="0.6s">
					{collections[sliderkey].desc} 
					</p>
				</div>
				<div className="leftsideBottamimage wow fadeInUp" data-wow-delay="0.6s">
					<Image src={bgImageslider} />
				</div>
			</Col>
			<Col md={6}>
			<div className="wow fadeInUp" data-wow-delay="0.3s">
				<Carousel activeIndex={index} onSelect={handleSelect}>
					<Carousel.Item>
						<div className="opacitynone"><img
						className="d-block w-100"
						src="http://localhost:3000/static/media/TopbannerAbout.8fab7366.png"
						alt="First slide"
						/></div>
						<Carousel.Caption>
							<div className="AboutsliderImages_main">
								<div className={sliderkey === "0" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("0")}> <Image src={sliderimage1}  /> </div>
								<div className={sliderkey === "1" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("1")}> <Image src={sliderimage2}  /> </div>
								<div className={sliderkey === "2" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("2")}> <Image src={sliderimage3}  /> </div>
								<div className={sliderkey === "3" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("3")}> <Image src={sliderimage4}  /> </div>
							</div>
						</Carousel.Caption> 
					</Carousel.Item>
					<Carousel.Item>
						<div className="opacitynone"><img
						className="d-block w-100"
						src="http://localhost:3000/static/media/TopbannerAbout.8fab7366.png"
						alt="Second slide"
						/></div>

						<Carousel.Caption>
							<div className="AboutsliderImages_main">
								<div className={sliderkey === "4" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("4")}> <Image src={sliderimage1}  /> </div>
								<div className={sliderkey === "5" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("5")}> <Image src={sliderimage2}  /> </div>
								<div className={sliderkey === "6" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("6")}> <Image src={sliderimage3}  /> </div>
								<div className={sliderkey === "7" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("7")}> <Image src={sliderimage4}  /> </div>
							</div>
						</Carousel.Caption> 
					</Carousel.Item>
					<Carousel.Item>
						<div className="opacitynone"><img
						className="d-block w-100"
						src="http://localhost:3000/static/media/TopbannerAbout.8fab7366.png"
						alt="Third slide"
						/></div>

						<Carousel.Caption>
							<div className="AboutsliderImages_main">
								<div className={sliderkey === "0" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("0")}> <Image src={sliderimage1}  /> </div>
								<div className={sliderkey === "1" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("1")}> <Image src={sliderimage2}  /> </div>
								<div className={sliderkey === "2" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("2")}> <Image src={sliderimage3}  /> </div>
								<div className={sliderkey === "8" ? "AboutsliderImages active" : "AboutsliderImages"} onClick={() => setSliderkey("8")}> <Image src={sliderimage4}  /> </div>
							</div>
						</Carousel.Caption>  
					</Carousel.Item>
					</Carousel>
				</div>
			</Col>
        </Row>
	  
	  </>

	 );
}
export default AboutSlider;