import './style.scss';
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import {useState} from 'react';

function Contactform (){  

    const[fname, setFname] = useState("");
    const[lname, setLname] = useState("");
    const[mobilenumber, setMobilenumber] = useState("");
    const[email, setEmail] = useState("");
    const[seterror, setError] = useState(false);
    const[sendmessage, setSendmessage] = useState();
    const[loader, setLoader] = useState(false);
    const[thankyou, setThankyou] = useState(false);

    const handlesubmit = (e) => {
        e.preventDefault();
        setSendmessage(sendmessage);   
        setLoader(true);  

        if (fname === "" || lname === "" || mobilenumber === "" || email === ""){
            setError(true);
        }
        else {
            setError(false);
            setThankyou(true);
        }
    }


    return (
        <>
        <div className='bg-contacthalf  wow fadeInUp' data-wow-delay="0.3s">
            <Container>
                <Form className='Contactform_body'>
                    <h3>Get in Touch</h3>
                    <Row>
                        <Col>
                            <Form.Group  className="mb-3">
                                <Form.Control type="text" placeholder="First name" onChange={(e) => setFname(e.target.value)} />
                                {seterror && <p className='errormessase'>This is required field</p>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Last name" onChange={(e) => setLname(e.target.value)} />
                                {seterror && <p className='errormessase'>Last Name is required</p>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col> 
                            <Form.Group className="mb-3">
                                <Form.Control type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                {seterror && <p className='errormessase'>Email is required</p>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control type="number" placeholder="Mobile number" onChange={(e) => setMobilenumber(e.target.value)}/>
                                {seterror && <p className='errormessase'>Mobile Number is required</p>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control as="textarea" rows={3} placeholder='Message' />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Form.Group className="mb-3"  controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="I would like to receive the latest news from Algoan." />
                    </Form.Group>
                   

                    <Button type="submit" className='btn btn-orange' onClick={handlesubmit}>  Submit </Button>
                
                    <div>
                    {thankyou && <>
                        <div className='Thankyoumessage'> <h4>Thank you for the message. We will contact you soon.</h4></div>
                    </>}
                    </div>
                </Form>
            </Container>
        </div>

       

        </>
    )
}
export default Contactform;