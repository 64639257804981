import './style.scss';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Button} from 'react-bootstrap';

function PlanListing ({pricingchangeClass, TitlePrice, Price, Btntext, animationtimer, option1=false, option2=false, option3=false, option4=false, option5=false, option6=false}){  

    return (
        <>
            <div className={pricingchangeClass}>
            <div className='PriceListmain wow fadeInUp' data-wow-delay={animationtimer}>
                <div className='pricelistHeading'> <h3>{TitlePrice}</h3> </div>
                <div className='pricelistAmout'>
                    <h2>{Price}</h2>
                    <p>Per Month</p>
                </div>
                
                <div className='pricelistCheckbox'>
                {option1 === true ? <p><FaCheck /> <span className='priceinglistname_mobile'> Unlimited Token</span></p> : <p>&nbsp; <span className='priceinglistname_mobile'><FaTimes /> Unlimited Token</span></p>}
                {option2 === true ? <p><FaCheck /> <span className='priceinglistname_mobile'> Unlimited Sub-token</span> </p>   : <p>&nbsp; <span className='priceinglistname_mobile'><FaTimes /> Unlimited Sub-token</span></p>}
                {option3 === true ? <p><FaCheck /> <span className='priceinglistname_mobile'> Unlimited Storage</span> </p>   : <p>&nbsp; <span className='priceinglistname_mobile'><FaTimes /> Unlimited Storage</span></p>}
                {option4 === true ? <p><FaCheck /> <span className='priceinglistname_mobile'> Unlimited bandwidth</span> </p>   : <p>&nbsp; <span className='priceinglistname_mobile'><FaTimes /> Unlimited bandwidth</span></p>}
                {option5 === true ? <p><FaCheck /> <span className='priceinglistname_mobile'> Unlimited Mail Account</span> </p>   : <p>&nbsp; <span className='priceinglistname_mobile'><FaTimes /> Unlimited Mail Account</span></p>}
                {option6 === true ? <p><FaCheck /> <span className='priceinglistname_mobile'> Unlimited Domain</span></p>   : <p>&nbsp; <span className='priceinglistname_mobile'><FaTimes /> Unlimited Domain</span></p>}
                </div>

                <div className='pricelistButton'>
                    <Button className="btn_orange_border">{Btntext}</Button>
                </div>
            </div>
            </div>
        </>
    )
}
export default PlanListing;