
import Centerheading from '../../Components/Centerheading';
import MultichainCircel from './MultichainCircel';
import Accordionfaq from '../../Components/Accordian';
import './style.scss';

import { Container, Image} from 'react-bootstrap';

function FAQ (){  
	return (
      <>
	  <div className='faq_main'>
		  <Container>
				<Centerheading Heading="&nbsp;" 
				HeadingColor="Frequently Asked Questions" 
				headingclass="blue_heading" />
          
		  		<div className='faq_accordianspace'>
		  		<Accordionfaq animationtimer="0.3s"
                Questionfaq = "How Long Will It Take For My Order To Arrive After I Make Payment?" 
                Answerfaq = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing " 
                />

                <Accordionfaq eventKey="1"  animationtimer="0.6s"
                Questionfaq = "How Long Will It Take For My Order To Arrive After I Make Payment?" 
                Answerfaq = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing" 
                />

				<Accordionfaq eventKey="2"  animationtimer="0.9s"
                Questionfaq = "How Long Will It Take For My Order To Arrive After I Make Payment?" 
                Answerfaq = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing" 
                />
				
				</div>
         </Container>
      </div>
	  </>
	 ) 
}
export default FAQ;