
import Centerheading from '../../Components/Centerheading';
import {Container, Image} from 'react-bootstrap';
import './style.scss';
import partnerIcon1 from '../../Static/partner-mgnr.svg';
import partnerIcon2 from '../../Static/partner-ellipt.svg';
import partnerIcon3 from '../../Static/partner-wintermute.svg';
import partnerIcon4 from '../../Static/partner-gbv.svg';

function Partners (){  
	return (
      <>
	  <div className='Partners_main'>
		  <Container>
				<Centerheading Heading="Our Partners" 
				HeadingColor="&nbsp;" 
				headingclass="bgblue_heading" 
                Description="Our team of Happiness Engineers works remotely from 58 countries providing customer support across multiple time zones." />
          
		  		
                  <div className='partnericon_bg'>
                      <div className='PartnerIcon wow zoomIn' data-wow-delay="0.3s"><Image src={partnerIcon1} /></div>
                      <div className='PartnerIcon wow zoomIn' data-wow-delay="0.6s"><Image src={partnerIcon2} /></div>
                      <div className='PartnerIcon wow zoomIn' data-wow-delay="0.9s"><Image src={partnerIcon3} /></div>
                      <div className='PartnerIcon wow zoomIn' data-wow-delay="1.2s"><Image src={partnerIcon4} /></div>
                  </div>


         </Container>
      </div>
	  </>
	 ) 
}
export default Partners;