
import Centerheading from '../../Components/Centerheading';
import MultichainCircel from './MultichainCircel';

import './style.scss';

function MultichainIndices (){  
	return (
      <>
	  <div className='MultichainIndices_main'>
          <Centerheading Heading="Multichain" 
		  HeadingColor="Indices" 
		  headingclass="blue_heading" />
          
          <MultichainCircel />

      </div>
	  </>
	 ) 
}
export default MultichainIndices;