import {Button, Container, Nav, NavDropdown, Image} from 'react-bootstrap';
import { Link } from "react-router-dom";
import './style.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

function Progressbar ({percentage=66}){  
   
	return (
      <>
        <div style={{ width: 48, height: 48 }}>
            <CircularProgressbar 
            value={percentage} 
            text={`${percentage}%`} 
            styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: 'butt',
                textSize: '28px',
                pathTransitionDuration: 0.5,
                textColor: 'rgba(255, 255, 255, 0.84)',
                trailColor: '#486E88',
                backgroundColor: '#3e98c7',
                pathColor: "#FF2F01",
              })}
             />
        </div>
	  </>
	 )
}
export default Progressbar;