import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Centerheading from '../../Components/Centerheading';
import "./style.scss";
import TopbannerAbout from "./TopbannerAbout";
import FooterSubscription from '../../Components/FooterSubscription';
import {WOW} from 'wowjs';
import {useEffect, useState} from 'react';

function About (){  

  
  const[topPosition,setTopPosition]=useState(0);
  useEffect(() => {
    
    if(topPosition === 10){
      const wow = new WOW({
        live: false
      }).init();
    }
    
  }, [topPosition])
  useEffect(() => {
   
   let interval =  setInterval(function(){
      var top  = window.pageYOffset || document.documentElement.scrollTop;
      if(top <= 0){ setTopPosition(10); clearInterval(interval);}
      
    },100);
     //setTopPosition(top);
  },[]);
	return (
      <>
      <div className='bg_blue_full'>
        <Header/>
           
            <TopbannerAbout />
            <FooterSubscription />

        <Footer/>
      </div>
	  </>

	 ) 
}
export default About;