import Header from '../../Components/Header';
import Centerheading from '../../Components/Centerheading';
import Topbanner from './Topbanner';
import { Container} from 'react-bootstrap';
import './style.scss';
import Buttontext from '../../Components/Button';
import Getaccess from './Getaccess';
import MultichainIndices from './MultichainIndices';
import LeftRight from './LeftRight';
import DiscoverAPI from './DiscoverAPI';
import FAQ from './FAQ';
import Partners from './Partners';
import Getstartbar from './Getstartbar';
import Footer from '../../Components/Footer';

import DashboardImage from '../../Static/Dashboard_image.png';
import NewsAPI from '../../Static/Newsapi.png';
import DiscoveAPIImage from '../../Static/DiscoverAPI_image.png';

function HomePage (){  
	return (
      <>
	  <div className='bg_blue'>
	 	<Container>
		  <Header/>
		  <Centerheading Heading="Discover api with an" 
		  HeadingColor="illustration" 
		  Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has " 
		  headingclass="bgblue_heading" />
		</Container>
		<Topbanner />
		<div className="text-center btndetails">
			<Buttontext Btntext="Explore Docs" btnclass="btn_orange_border" />
			<Buttontext Btntext="View Plans" btnclass="btn-orange"/>
		</div>
		<Getaccess />
	  </div>

	  <div >
		<MultichainIndices />
	  </div>

	  <div className='bg_blue_middle'>
		  <LeftRight rightsideimage={DashboardImage} type={1} />

		  <DiscoverAPI heading="Discover" headingcolor="API"
		  imageDiscoversrc={DiscoveAPIImage} />

		  <LeftRight rightsideimage={NewsAPI} type={2}/>
	  </div>

	  <FAQ />

	  <div className='bg_blue_middle'>
	  <Partners />
	  <Getstartbar />
	  <Footer/>
	  </div>

		
	  </>
	 ) 
}
export default HomePage;