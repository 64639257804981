import { Form} from 'react-bootstrap';
import "./style.scss";
import { FaArrowRight } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Container, Row, Col, Image} from 'react-bootstrap';

function FooterSubscription (){  
	return (
      <>
      <Container>
        <Row>
            <Col>
            <div className="footer_subscrption wow fadeInUp" data-wow-delay="0.3s">
                <h2>Stay up to date with the latest insights</h2>
                <Form className='footer_form_email'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Enter Registered Email" />
                    </Form.Group>
                    <Button className='btn btn_arrow' type="submit">
                        <FaArrowRight/>
                    </Button>
                </Form>
            </div>
            </Col>
        </Row>
        </Container>
	  </>

	 ) 
}
export default FooterSubscription;