import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import './style.scss';

import FooterSubscription from '../../Components/FooterSubscription';
import IndicesList from './IndicesList';

import {Col, Container, Row, Image} from 'react-bootstrap';

import PlayIcon from '../../Static/Play.svg';
import DefiIcon from '../../Static/DeFiL1.svg';
import BCP from '../../Static/BCP.svg';
import DeFiPlus from '../../Static/DeFiPlus.svg';
import {WOW} from 'wowjs';
import {useEffect, useState} from 'react';

function Indices (){  
    
    const[topPosition,setTopPosition]=useState(0);
    useEffect(() => {
      
      if(topPosition === 10){
        const wow = new WOW({
          live: false
        }).init();
      }
      
    }, [topPosition])
    useEffect(() => {
     
     let interval =  setInterval(function(){
        var top  = window.pageYOffset || document.documentElement.scrollTop;
        if(top <= 0){ setTopPosition(10); clearInterval(interval);}
        
      },100);
       //setTopPosition(top);
    },[]);

    return (
        <>
         <div className='bg_blue_full'>
        <Header/>
        <div className='Indices_main'>
        <Container>
            <Row>
                <Col>
                    <div className='Indices_heading wow fadeInUp' data-wow-delay="0.3s">
                        <h3>A decentralized asset manager for tokenized portfolios, with a mission to bring automated wealth creation to everyone with an internet connection.</h3>
                    </div>
                </Col>
            </Row>
            <Row className='IndicesLinks'>

                <IndicesList IndicesImage={PlayIcon}
                IndicesHeading="PLAY" 
                IndicesListAmount="$ 2.57" 
                IndicesListScore={7.49}
                IndicesDescription="Complete exposure to the Metaverse and NFTs. In collab with NFTX" 
                IndicesListMainAmount="158.00 %" 
                animaticontimer="0.2s" />
                
                <IndicesList IndicesImage={DefiIcon} 
                IndicesHeading="DEFI+L" 
                IndicesListAmount="$ 1.86" 
                IndicesListScore={5.85}
                IndicesDescription="Large market cap projects. A premium selection of blue chip only tokens." 
                IndicesListMainAmount="86.00 %" 
                animaticontimer="0.4s"
                />

                <IndicesList IndicesImage={BCP} 
                IndicesHeading="BCP" 
                IndicesListAmount="$ 2.73" 
                IndicesListScore={.49}
                IndicesDescription="Balanced Crypto Pie. An even split between BTC, ETH, and the best of DEFI." 
                IndicesListMainAmount="173.25 %" 
                animaticontimer="0.6s" 
                />

                <IndicesList IndicesImage={DeFiPlus} 
                IndicesHeading="DEFI++" 
                IndicesListAmount="$ 2.29" 
                IndicesListScore={-2.00}
                IndicesDescription="Balanced Crypto Pie. An even split between BTC, ETH, and the best of DEFI." 
                IndicesListMainAmount="129.19 %"  
                animaticontimer="0.8s" />

                <IndicesList IndicesImage={DefiIcon} 
                IndicesHeading="DEFI+S" 
                IndicesListAmount="$ 3.22" 
                IndicesListScore={-9.31}
                IndicesDescription="Balanced Crypto Pie. An even split between BTC, ETH, and the best of DEFI." 
                IndicesListMainAmount="222.05 %" 
                animaticontimer="0.10s" />
                
            </Row>
        </Container>
        </div>
        
        <FooterSubscription />
        <Footer/>
        </div>
            
        </>
    )
}
export default Indices;