
import {Col, Container, Image, Row} from 'react-bootstrap';
import './style.scss';
import { FaCheck } from 'react-icons/fa';

function SubheadingPoint ({subheading, subdescription}){  
	return (
      <>
	  <div className='subheadingpoints_main wow fadeInUp' data-wow-delay="0.5s">
          <h4><FaCheck/> {subheading} </h4>
          <p>{subdescription}</p>
      </div>
	  </>
	 ) 
}
export default SubheadingPoint;