
import {Navbar, Container, Nav, NavDropdown, Image} from 'react-bootstrap';
import './style.scss';
import opbanner from '../../Static/token.svg';


function Topbanner (){  
	return (
      <>
	  <div className='topbanner_image Imageshadow_main'>
	  	<object type="image/svg+xml" data={opbanner}>svg-animation</object>
	  </div>
	  </>
	 ) 
}
export default Topbanner;