import {Navbar, Container, Nav, NavDropdown, Image} from 'react-bootstrap';
import { Link } from "react-router-dom";
import './style.scss';
import React from 'react';
import { Line } from 'react-chartjs-2';


function Graph (){  

    const data = {
        labels: ["test", "test", "test", "test", "test", "test", "test", "test", "test", "test", "test"],
        datasets: [
          {
            label: "First dataset",
            data: [88, 95, 65, 75, 68, 85, 75, 77, 88, 92, 45],
            fill: true,
            backgroundColor: "#F2994A",
            borderColor: "#F2994A",
            color:"#A3A3A3"
          }
        ],
      };
      

	return (
      <>
       <Line data={data} />
	  </>
	 )
}
export default Graph;