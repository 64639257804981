import {Button, Container, Nav, NavDropdown, Image} from 'react-bootstrap';
import { Link } from "react-router-dom";
import './style.scss';

function Buttontext ({Btntext, btnclass}){  
	return (
      <>
        <Button  className={btnclass}>{Btntext}</Button>
	  </>
	 )
}
export default Buttontext;