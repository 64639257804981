import {Navbar, Container, Nav, NavDropdown, Image, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Logo from '../Static/Logo.svg';
import { FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Link } from "react-router-dom";

function Header (){  
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>  You Tube </Tooltip>
      );
      const twitterTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>  Twitter </Tooltip>
      );
      const LinedinTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>  Linkedin </Tooltip>
      );


     const handleToggle = () => {
        document.body.classList.toggle("fixmenu");
     }

	return (
      <>
        <Navbar expand="lg" className='tokenstream_header'>
        <Container>
            <Navbar.Brand href="/">
                <Image src={Logo} alt="tokenstream" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="justify-content-end align-item-center rightside-space">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    > 
                    <Link to="/" > <FaYoutube /> <span className='mobileviewshoiw'> You tube</span> </Link> 
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={twitterTooltip}
                    > 
                    <Link to="/" > <FaTwitter/> <span className='mobileviewshoiw'> Twitter</span> </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={LinedinTooltip}
                    > 
                    <Link to="/" > <FaLinkedin/> <span className='mobileviewshoiw'> Linkedin </span>  </Link>
                    </OverlayTrigger>
                    <a href="https://quizzical-agnesi-983dbf.netlify.app/#/dashboard" target='_blank' className='btn btn-orange' > Launch app </a>
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
	  </>
	 ) 
}
export default Header;