import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Centerheading from '../../Components/Centerheading';
import FooterSubscription from '../../Components/FooterSubscription';
import Contactform from './Contactform';
import {WOW} from 'wowjs';
import {useEffect, useState} from 'react';

function Contact (){  
    
    // useEffect(() => {
    //     const wow = new WOW({
    //       live: false
    //     }).init();
    //   }, [])

    const[topPosition,setTopPosition]=useState(0);
    useEffect(() => {
      
      if(topPosition === 10){
        const wow = new WOW({
          live: false
        }).init();
      }
      
    }, [topPosition])
    useEffect(() => {
    
    let interval =  setInterval(function(){
        var top  = window.pageYOffset || document.documentElement.scrollTop;
        if(top <= 0){ setTopPosition(10); clearInterval(interval);}
        
      },100);
    },[]);

    return (
        <>
         <div className='bg_blue_full Contactus'>
        <Header/>
           
            <Centerheading  Heading="Contact Us" 
            HeadingColor="&nbsp;" 
            headingclass="Contactheading" 
            Description="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." />
        
            <Contactform />
            <FooterSubscription />

        <Footer/>
        </div>
            
        </>
    )
}
export default Contact;