import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import './style.scss';

import Centerheading from '../../Components/Centerheading';
import FooterSubscription from '../../Components/FooterSubscription';
import PricingTop from '../../Static/PricingBanner.png';

import { Container, Image, Row, Col, Button} from 'react-bootstrap';
import PlanListing from './PlanListing';
import opbanner from '../../Static/Price-page-animation.svg';
import {WOW} from 'wowjs';
import {useEffect, useState} from 'react';

function Pricing (){  
    
    const[topPosition,setTopPosition]=useState(0);
  useEffect(() => {
    
    if(topPosition === 10){
      const wow = new WOW({
        live: false
      }).init();
    }
    
  }, [topPosition])
  useEffect(() => {
   
   let interval =  setInterval(function(){
      var top  = window.pageYOffset || document.documentElement.scrollTop;
      if(top <= 0){ setTopPosition(10); clearInterval(interval);}
      
    },100);
     //setTopPosition(top);
  },[]);

    return (
        <>
        <div className='bg_blue_full pricingpage'>
            <Header/>
            
                <Centerheading  Heading="Pricing" 
                HeadingColor="&nbsp;" 
                headingclass="Contactheading" 
                Description="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." />
            
                <Container>
                    <div className='PricingBannerTop'>
                        <object type="image/svg+xml" data={opbanner}>svg-animation</object>
                    </div>
                </Container>

                <Container className='Choose_Planlist_main'>
                    <Row>
                        <Col md={3}> 
                            <div className='planListing mobileview_list wow fadeInLeft' data-wow-delay="0.3s">
                                <div className='pricelistHeading'> <h3> Plans </h3> </div>
                                <div className='pricelistAmout'> <h2>Main Features</h2> </div>
                                <div className='pricelistCheckbox'>
                                    <p> Unlimited Token </p>
                                    <p> Unlimited Sub-token</p>
                                    <p> Unlimited Storage</p>
                                    <p> Unlimited bandwidth</p>
                                    <p> Unlimited Mail Account</p>
                                    <p> Unlimited Domain</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}> 
                            <PlanListing 
                            pricingchangeClass= "PricingList_Free" 
                            TitlePrice="Free" 
                            Price="$0" 
                            animationtimer="0.3s" 
                            option1={true} option2={false} option3={false} option4={false} option5={false} option6={false} 
                            Btntext="Get free API Key" 
                            />
                        </Col>

                        <Col md={3}> 
                            <PlanListing 
                            pricingchangeClass= "PricingList_Standard" 
                            TitlePrice="Standard" 
                            Price="$20" 
                            animationtimer="0.5s" 
                            option1={true} option2={true} option3={true} option4={true} option5={false} option6={false}
                            Btntext="Get  Started" 
                            /> 
                        </Col>

                        <Col md={3}> 
                            <PlanListing 
                            pricingchangeClass= "PricingList_Advanced" 
                            TitlePrice="Advanced" 
                            Price="$60" 
                            animationtimer="0.6s" 
                            option1={true} option2={true} option3={true} option4={true} option5={true} option6={true} 
                            Btntext="Get  Started" 
                            /> 
                        </Col>

                    </Row>
                </Container>

                <div className="text-center btndetails">
                    <Button className="btn-orange">Explore documentation</Button>
                </div>
               

                <FooterSubscription />

            <Footer/>
        </div>
        </>
    )
}
export default Pricing;