import React,{useState} from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { UserContext } from './lib/UserContext';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';

import HomePage from "./Pages/Home/HomePage";
import About from "./Pages/About/About";
import Contact from './Pages/Contact/Contact';
import Pricing from './Pages/Pricing/Pricing';
import Indices from './Pages/Indices/Indices';
import IndicesDetails from './Pages/Indices/IndicesDetails';
import { CircularProgressbar } from 'react-circular-progressbar';


function App() {
	const [loginUser, setLoginUser] = useState(null);
  return (
    <UserContext.Provider value={[loginUser, setLoginUser]}>
    <Router>
      <Switch>
          <Route
          exact
          path="/"
          render={() => <Redirect to="/home" />}
        />
        <PublicRoute path="/home" component={HomePage} />
        <PublicRoute path="/about" component={About} />
        <PublicRoute path="/contact" component={Contact} />
        <PublicRoute path="/pricing" component={Pricing} />
        <PublicRoute path="/indices/indicesdetails" component={IndicesDetails} />
        <PublicRoute path="/indices" component={Indices} />
        
        
        

       </Switch>
    </Router>
	 </UserContext.Provider>
  );
  
  function PublicRoute({ component, ...rest } : any) {
    return (
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
    );
  }
  function PrivateRoute({ component, ...rest } : any) {
    return (
	localStorage.getItem('checkLogin') === 'solpulseadmin' ?
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
	  :
	  <Redirect to="/login" />
	  
    );
  }
}

export default App;
