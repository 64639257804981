import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import './style.scss';
import FooterSubscription from '../../Components/FooterSubscription';

import {Col, Container, Row, Image, Button} from 'react-bootstrap';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import PlayIcon from '../../Static/Play.svg';
import Graph from '../../Components/Graph';
import TreeTable from '../../Components/TreeTable';
import Indicestabs from '../../Components/Indicestabs';
import {WOW} from 'wowjs';
import {useEffect, useState} from 'react';

function IndicesDetails (){  
    
    const[topPosition,setTopPosition]=useState(0);
    useEffect(() => {
      
      if(topPosition === 10){
        const wow = new WOW({
          live: false
        }).init();
      }
      
    }, [topPosition])
    useEffect(() => {
     
     let interval =  setInterval(function(){
        var top  = window.pageYOffset || document.documentElement.scrollTop;
        if(top <= 0){ setTopPosition(10); clearInterval(interval);}
        
      },100);
       //setTopPosition(top);
    },[]);

    return (
        <>
        <div className='bg_blue_full'>
        <Header/>
        <div className='bg_detailIndices'>
            <Container>
            <div className='IndicesDetailspage'>
                <div className='IndicesDetails_main wow fadeInUp' data-wow-delay="0.2s">
                    <div className='d-flex IndicesDetails_header'>
                        <div className='IndicesDetails_left'>
                            <div className='IndicesDetails_image'> <Image src={PlayIcon} /></div>
                            <div className='IndicesDetails_contant'>
                                <h3>PLAY</h3>
                                <p className='IndicesDetails_contant'>Metaverse NFT Index</p>
                                <p className='IndicesDetails_scoreamount'>
                                    <span className='IndicesList_Amount'>$2.57</span>
                                    <span className='greentext_amount'> <FaArrowUp/> 7.49 %</span>
                                </p>
                            </div>
                        </div>
                        <div className='IndicesDetails_right'>
                            <div className='IndicesDetails_buysell'>
                                <Button className='btn btn-orange '>Buy & Sell</Button>
                            </div>
                            <div className='IndicesDetails_tabs'>
                                <ul>
                                    <li>
                                        <div>
                                            <p className='heading_details'>NAV</p>
                                            <p>$2.67</p>
                                        </div>
                                    </li>
                                    <li>
                                        <p className='heading_details'>Discount</p>
                                        <p>0.74%</p>
                                    </li>
                                    <li>
                                        <p className='heading_details'>Tot APY</p>
                                        <p>0.00%</p>
                                    </li>
                                    <li>
                                        <p className='heading_details'>Market Cap</p>
                                        <p>$ 1,890,569.68</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='IndicesDetails_main  wow fadeInUp'  data-wow-delay="0.2s">
                   <div className='Graph_indice_main'>
                        <div>

                        </div>
                        <Graph /> 
                    </div>  
                </div>

                <div className='IndicesDetails_main  wow fadeInUp'  data-wow-delay="0.2s">
                    <div className='Tabletree_indice_main'>
                        <h3>Allocation breakdown</h3>
                        <TreeTable /> 
                    </div>
                </div>

                <div className='IndicesDetails_main  wow fadeInUp'  data-wow-delay="0.2s">
                    <div className='Indices_tabs_main'>
                        <Indicestabs />
                    </div>
                </div>

            </div>

            </Container>
            </div>
            <FooterSubscription />
            <Footer/>
        </div>
        </>
    )
}
export default IndicesDetails;