
import "./style.scss";
import {useState} from 'react';
import { Container, Row, Col, Image} from 'react-bootstrap';
import Topbannerimage from '../../Static/TopbannerAbout.png';
import AboutHeading from './AboutHeading';
import AboutSlider from "./AboutSlider";

function TopbannerAbout (){  

	return (
      <>
       <div className='margintop bgRightLogo'>
        <Container fluid>
            <Row className='align-item-center'>
                <Col md={6}>
                    <div className='img_responsive wow fadeInLeft' data-wow-delay="0.3s">
                        <Image src={Topbannerimage} />
                    </div>
                </Col>
                <Col md={6}>
                    <AboutHeading AboutHeading="Why choose the " 
                    AboutHeadingColor="TOKENSTREAM?" 
                    AboutDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. " 
                    AboutDescription2="It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." 
                    />
                </Col>
            </Row>
        </Container>
        </div>
        <Container>
            <AboutSlider />
        </Container>
	  </>

	 ) 
}
export default TopbannerAbout;