
import {Image} from 'react-bootstrap';
import './style.scss';
import logocenterMultichain from '../../Static/centerlogoMultichain.svg';
import iconpolygonToken from '../../Static/icon_polygon-token.svg';
import iconPolygonPink from '../../Static/iconPolygonPink.svg';
import Iconsolana from '../../Static/Iconsolana.svg';
import iconlightsecond from '../../Static/iconlightsecond.svg';
import IconLink from '../../Static/IconLink.svg';

function MultichainCircel (){  
	return (
      <>
	  <div className='Multichaincircel_main' >
          <div className='blueborder'>
                <span className='pointspace'></span>
                <div className='icon_first'><Image src={iconpolygonToken} /></div>
                <div className='circelcontant_top wow fadeInRight' data-wow-delay="0.3s"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p></div>
                <div className='icon_second'><Image src={iconPolygonPink} /></div>

                <div className='lightorange'>
                    <div className='icon_lightorange1'><Image src={Iconsolana} /></div>
                    <div className='circelcontant_top circelcontant_center wow fadeInLeft' data-wow-delay="0.6s"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p></div>

                    <div className='icon_lightorange2'><Image src={iconlightsecond} /></div>
                    <div className='circelcontant_top wow fadeInRight' data-wow-delay="0.9s"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p></div>

                    <div className='lightblueborder'>
                        <span className='pointspace'></span>
                        <span className='pointspace2'></span>
                         <div className='icon_lightblueborder1'><Image src={IconLink} /></div>
                        <div className='darkorangeborder'>
                        <span className="pointspace"></span>
                            <div className='centerlogo_multichain'>
                                <Image src={logocenterMultichain} />
                            </div>
                        </div>
                    </div>
                </div>
          </div>
      </div>
	  </>
	 ) 
}
export default MultichainCircel;