import {Col, Container, Row, NavDropdown, Image} from 'react-bootstrap';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import './style.scss';
import { Link } from "react-router-dom";


function IndicesList ({IndicesImage, IndicesHeading, IndicesListAmount="", animaticontimer, IndicesListScore=0, IndicesDescription, IndicesListMainAmount }){  
    
    return (
        <>
            <Link to="/indices/indicesdetails" >
            <div className='IndicesList_main wow fadeInUp' data-wow-delay={animaticontimer}>
                <div className='Indices_left'>
                    <div className='IndicesList_image'>
                        <Image src={IndicesImage} />
                    </div>
                    <div className='IndicesList_middle'>
                        <h3>{IndicesHeading}</h3>


                            <span className='IndicesList_Amount'>{IndicesListAmount}</span>
                            {IndicesListScore > 0 ? <span className='greencolor_indices'><FaArrowUp/> {IndicesListScore}%</span> : <span className='redcolor_indices'><FaArrowDown/> {IndicesListScore}%</span>}
                            
                         
                        <p>{IndicesDescription}</p>
                    </div>
                </div>
                <div className='IndicesList_inception'>
                    <p>Since inception</p>
                    <h5>{IndicesListMainAmount}</h5>
                </div>
            </div>
            </Link>
        </>
    )
}
export default IndicesList;