import "./style.scss";

function AboutHeading ({AboutHeading, AboutHeadingColor="", AboutDescription, AboutDescription2=""}){  
	return (
      <>
        <div className='Abouttopcontant'>
            <h2  className='wow fadeInUp' data-wow-delay="0.3s">{AboutHeading} <span>{AboutHeadingColor}</span></h2>
            <p className='wow fadeInUp' data-wow-delay="0.6s">{AboutDescription}</p>
            <p className='wow fadeInUp' data-wow-delay="0.8s">{AboutDescription2}</p>
        </div>
	  </>

	 ) 
}
export default AboutHeading;